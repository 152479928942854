@import "https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap";
:root {
  --primary-color: #4caf50;
  --secondary-color: #45a049;
  --background-color: #1a2c42;
  --surface-color: #2c3e50;
  --text-color: #ecf0f1;
  --input-bg-color: #34495e;
  --input-focus-color: #3a536b;
  --shadow-color: #0003;
  --warning-color: #ca3737cf;
  --warning-color-hover: #af0000ae;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  font-family: Poppins, sans-serif;
  line-height: 1.6;
  display: flex;
}

.container {
  background-color: var(--surface-color);
  box-shadow: 0 10px 30px var(--shadow-color);
  border-radius: 20px;
  width: 100%;
  max-width: 800px;
  overflow: hidden;
}

header {
  background-color: var(--primary-color);
  color: var(--text-color);
  text-align: center;
  padding: 20px;
}

.logo {
  width: 400px;
}

h1 {
  font-size: 28px;
  font-weight: 600;
}

main {
  padding: 20px;
}

.player-setup {
  margin-bottom: 20px;
}

.player-count-select {
  text-align: center;
  margin-bottom: 15px;
}

select, .player-name {
  background-color: var(--input-bg-color);
  color: var(--text-color);
  border: none;
  border-radius: 8px;
  width: 100%;
  padding: 12px;
  font-size: 16px;
  transition: all .3s;
}

select:focus, .player-name:focus {
  background-color: var(--input-focus-color);
  box-shadow: 0 0 0 2px var(--primary-color);
  outline: none;
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ecf0f1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-position: right 12px center;
  background-repeat: no-repeat;
  background-size: 16px;
  padding-right: 40px;
}

.player-names {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  display: grid;
}

.score-table {
  border-collapse: separate;
  border-spacing: 10px;
  width: 100%;
}

.score-table th, .score-table td {
  text-align: center;
  padding: 12px;
  position: relative;
  overflow: hidden;
}

.score-table th {
  background-color: var(--secondary-color);
  color: var(--text-color);
  text-transform: uppercase;
  border-radius: 8px;
  font-weight: 600;
}

.score-table td {
  background-color: var(--input-bg-color);
  border-radius: 8px;
  transition: transform .3s, box-shadow .3s;
}

.score-table td:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px #0000001a;
}

input[type="number"] {
  text-align: center;
  color: var(--text-color);
  background-color: #0000;
  border: none;
  border-radius: 5px;
  width: 100%;
  padding: 8px;
  font-size: 16px;
  transition: all .3s;
}

input[type="number"]:focus {
  background-color: #ffffff1a;
  outline: none;
}

.total-row td {
  background-color: var(--secondary-color);
  color: var(--text-color);
  font-weight: 700;
}

.current-player {
  background-color: var(--input-bg-color);
  text-align: center;
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px;
}

.current-player p {
  margin-bottom: 15px;
  font-weight: 600;
}

.current-player input[type="number"] {
  background-color: var(--surface-color);
  width: 100%;
  max-width: 200px;
  margin-bottom: 15px;
  padding: 10px;
}

.action-buttons {
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  display: flex;
}

button {
  background-color: var(--primary-color);
  color: var(--text-color);
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 600;
  transition: all .3s;
}

button:hover {
  background-color: var(--secondary-color);
  transform: translateY(-3px);
  box-shadow: 0 5px 15px #0003;
}

.new-game {
  background-color: var(--warning-color);
}

.new-game:hover {
  background-color: var(--warning-color-hover);
}

footer {
  background-color: var(--input-bg-color);
  padding: 20px;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

@media (width <= 600px) {
  .logo {
    width: 300px;
  }
}

.footer-text {
  text-align: center;
  color: var(--text-color);
  margin-top: 20px;
  font-size: 14px;
}

.footer-text a {
  color: var(--primary-color);
  text-decoration: none;
}

.footer-text a:hover {
  text-decoration: underline;
}
/*# sourceMappingURL=index.9b6a693f.css.map */
