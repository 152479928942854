@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

:root {
  --primary-color: #4caf50;
  --secondary-color: #45a049;
  --background-color: #1a2c42;
  --surface-color: #2c3e50;
  --text-color: #ecf0f1;
  --input-bg-color: #34495e;
  --input-focus-color: #3a536b;
  --shadow-color: rgba(0, 0, 0, 0.2);
  --warning-color: #ca3737cf;
  --warning-color-hover: rgba(175, 0, 0, 0.684);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

.container {
  background-color: var(--surface-color);
  border-radius: 20px;
  box-shadow: 0 10px 30px var(--shadow-color);
  overflow: hidden;
  width: 100%;
  max-width: 800px;
}

header {
  background-color: var(--primary-color);
  color: var(--text-color);
  padding: 20px;
  text-align: center;
}

.logo {
  width: 400px;
}

h1 {
  font-size: 28px;
  font-weight: 600;
}

main {
  padding: 20px;
}

.player-setup {
  margin-bottom: 20px;
}

.player-count-select {
  margin-bottom: 15px;
  text-align: center;
}

select,
.player-name {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: var(--input-bg-color);
  color: var(--text-color);
  font-size: 16px;
  transition: all 0.3s ease;
}

select:focus,
.player-name:focus {
  outline: none;
  background-color: var(--input-focus-color);
  box-shadow: 0 0 0 2px var(--primary-color);
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ecf0f1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
}

.player-names {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
}

.score-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 10px 10px;
}

.score-table th,
.score-table td {
  padding: 12px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.score-table th {
  background-color: var(--secondary-color);
  color: var(--text-color);
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 8px;
}

.score-table td {
  background-color: var(--input-bg-color);
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.score-table td:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

input[type='number'] {
  width: 100%;
  padding: 8px;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  background-color: transparent;
  color: var(--text-color);
  transition: all 0.3s ease;
}

input[type='number']:focus {
  outline: none;
  background-color: rgba(255, 255, 255, 0.1);
}

.total-row td {
  font-weight: 700;
  background-color: var(--secondary-color);
  color: var(--text-color);
}

.current-player {
  background-color: var(--input-bg-color);
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
}

.current-player p {
  margin-bottom: 15px;
  font-weight: 600;
}

.current-player input[type='number'] {
  width: 100%;
  max-width: 200px;
  padding: 10px;
  margin-bottom: 15px;
  background-color: var(--surface-color);
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

button {
  padding: 12px 20px;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
}

button:hover {
  background-color: var(--secondary-color);
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.new-game {
  background-color: var(--warning-color);
}

.new-game:hover {
  background-color: var(--warning-color-hover);
}

footer {
  padding: 20px;
  background-color: var(--input-bg-color);
}

/* Mobile */
.table-container {
  overflow-x: auto;
  width: 100%;
}
@media (max-width: 600px) {
  .logo {
    width: 300px;
  }
}

.footer-text {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: var(--text-color);
}

.footer-text a {
  color: var(--primary-color);
  text-decoration: none;
}

.footer-text a:hover {
  text-decoration: underline;
}
